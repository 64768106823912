// see tailwind.config.js
export const SCREENS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1440,
}

export const TABLET_BREAKPOINT = SCREENS.md


export const isMobile = {
  Android: function() {
    return navigator!==null && navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator!==null && navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator!==null && navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator!==null && navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator!==null && navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};